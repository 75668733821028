import React from "react"
import { Link } from "gatsby"

const BackLink = ({
  text = "Back to PPE Needs Information",
  url = "/ppe_needs",
}) => (
  <Link
    to={url}
    className="font-bold no-underline mb-10 inline-block print:hidden"
  >
    &larr; {text}
  </Link>
)

export default BackLink
