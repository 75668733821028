import React from "react"
import PropTypes from "prop-types"
import BackLink from "./back-link"
import FaqGrid from "./faq-grid"
import Disclaimer from "./faq-content/disclaimer"

const FaqLayout = ({ children, title }) => {
  return (
    <div className="container">
      <BackLink />

      <div className="prose max-w-none mb-10">
        <h1>{title}</h1>
        <Disclaimer />
        {children}
      </div>

      <h2 className="mb-10">View Other PPE Guidance and FAQs</h2>

      <FaqGrid />
    </div>
  )
}

FaqLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FaqLayout
