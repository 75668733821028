import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import FaqLayout from "../../components/faq-layout"
import locale from "../../locale/en"
import FaceMasksContent from "../../components/faq-content/face-masks"

const { faq } = locale

const FaceMasks = () => (
  <Layout>
    <SEO title={faq.face_masks} />
    <FaqLayout title={faq.face_masks}>
      <FaceMasksContent />
    </FaqLayout>
  </Layout>
)

export default FaceMasks
