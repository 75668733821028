import React from "react"
import { Link } from "gatsby"
import Icon from "./icon"
import FAQ_CATEGORIES from "../lib/faq-categories"

const FaqGrid = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10">
      {FAQ_CATEGORIES.map(category => (
        <Link
          key={category.id}
          to={`/faqs/${category.id}`}
          className="flex flex-col items-center text-center p-10 border rounded-md hover:shadow-lg transition-shadow duration-150 cursor-pointer no-underline text-black"
        >
          <div className="h-24 w-24 mb-6">
            <Icon name={category.id} />
          </div>
          <p className="mb-0 font-bold">{category.name}</p>
        </Link>
      ))}
    </div>
  )
}

export default FaqGrid
