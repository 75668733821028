import React from "react"

const Disclaimer = () => (
  <>
    <p className="italic">
      Note: Please note that all information provided is general, and is an
      aggregation of and attributed to the sources included below. Use your own
      judgment and regulatory guidance to make decisions around PPE usage in the
      workplace.
    </p>
    <p>Last Updated: 9/14/20</p>
  </>
)

export default Disclaimer
