export default {
  faq: {
    face_masks: "Face Coverings and Masks",
    disposable_gloves: "Disposable Gloves",
    face_shields: "Face Shields and Goggles",
    hand_sanitizer: "Sanitizer",
    disinfectants: "Disinfectant wipes, sprays, and cleaners",
    impermeable_dividers: "Dividers",
    signage: "Floor Markers, Signage, and Ropes",
  },
  home: {
    title: "Understand your business’ PPE needs",
    disclaimer:
      "Note: Please note that the guidance provided by this platform is general, and is not a replacement for business leaders making evaluations of what is necessary for their specific contexts.",
    calculator_title: "PPE Planning Tool",
    calculator_description:
      "This calculator function can be used to create a shopping list based on parameters you select, such as the number of disposable gloves you would like to purchase per employee per week.",
    calculator_cta: "Estimate your PPE needs",
  },
  calculator: {
    title: "PPE Planning Tool",
    description:
      "Complete the below questionnaire to generate a customized PPE Shopping List for your business.",
  },
}
