import locale from "../locale/en"

export default [
  {
    id: "face_masks",
    name: locale.faq.face_masks,
  },
  {
    id: "disposable_gloves",
    name: locale.faq.disposable_gloves,
  },
  {
    id: "face_shields",
    name: locale.faq.face_shields,
  },
  {
    id: "hand_sanitizer",
    name: locale.faq.hand_sanitizer,
  },
  {
    id: "disinfectants",
    name: locale.faq.disinfectants,
  },
  {
    id: "impermeable_dividers",
    name: locale.faq.impermeable_dividers,
  },
  {
    id: "signage",
    name: locale.faq.signage,
  },
]
